import React, { useState, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
import { EVALUATION, EVALUATIONUPDATE, baseUrl } from "../../../Api/Api";
import Cookie from 'cookie-universal';
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import Loading from '../../../components/Loading';
export default function Evaluations () {
    const [loading, setLoading] = useState(false);
    const [selectedEvaluation, setSelectedEvaluation] = useState(null);
    const [run,setRun] = useState(1)
    const [evaluations,setEvaluations] = useState([])
    const show = useOutletContext();
    const cookie= Cookie();
    const token = cookie.get("pe");
    const [values, setValues] = useState({
        name : '',
        year : '',
        from : '',
        tw : '',
        initial_from :'',
        intial_to : '',
        final_from  :'',
        final_to : '',
    });
    useEffect(() => {
        setLoading(true)
        axios.get(`${baseUrl}/${EVALUATION}`,
        {headers : {Authorization : `Bearer ${token}`}}).then(data => {setEvaluations([data.data])}).catch((err) => console.log(err))
        setLoading(false)
    },[run])

    const clear = () => {
        setValues({
            name : '',
            year : '',
            from : '',
            tw : '',
            initial_from :'',
            intial_to : '',
            final_from  :'',
            final_to : '',
        })
        setSelectedEvaluation(null)
    }
    const update = () => {
        if ( values.name != '') {
            setLoading(true)
            axios.post(`${baseUrl}/${EVALUATIONUPDATE}`,values,{headers : {Authorization : `Bearer ${token}`}})
            .then(() => {clear();setRun(run+1)})
            .catch((err) => console.log(err))
        }
           
    }
    return (
        <main id="main" className={show ? "mainNarrow" : "mainWide"} style={{ direction: "rtl" }}>
             {loading && <Loading />}
            <div className="pagetitle">
                <h1>التقييمات</h1>
                <div className='d-flex mt-3'>
                    <Dropdown value={values} onChange={(e) => setValues(e.value)} options={evaluations} optionLabel="name" 
                    placeholder="اختر تقييم" className="w-full md:w-14rem" />
                    <Button icon="pi pi-minus-circle" outlined severity="secondary" aria-label="Bookmark" className='rounded-circle mx-2' onClick={clear}/>
                    <Button icon="pi pi-save" outlined severity="success" aria-label="Bookmark" className='rounded-circle mx-2' onClick={update}/>
                </div>
                <div className='d-flex flex-sm-row flex-column'>
                    <span className="p-float-label mx-4 my-3" style={{direction:"ltr"}}>
                        <InputText id="name" name="name" value={values.name} onChange={(e) => setValues({...values,[e.target.name] : e.target.value})} />
                        <label htmlFor="name">اسم التقييم</label>
                    </span>
                    <span className="p-float-label mx-4 my-2" style={{direction:"ltr"}}>
                        <InputText id="year" name="year" value={values.year} onChange={(e) => setValues({...values,[e.target.name] : e.target.value})} />
                        <label htmlFor="year">سنة التقييم</label>
                    </span>
                    <span className="p-float-label mx-4 my-2" style={{direction:"ltr"}}>
                    <InputText id="from" value={values.from} name='from' onChange={(e) => setValues({...values,[e.target.name] : e.target.value})} />
                        <label htmlFor="from">من</label>
                    </span>
                    <span className="p-float-label mx-4 my-2" style={{direction:"ltr"}}>
                    <InputText id="tw" value={values.tw} name="tw" onChange={(e) => setValues({...values,[e.target.name] : e.target.value})} />
                        <label htmlFor="tw">الى</label>
                    </span>
                </div>
                <div className='d-flex flex-sm-row flex-column'>
                    <span className="p-float-label mx-4 my-2" style={{direction:"ltr"}}>
                    <InputText id="initial_from" value={values.initial_from} name='initial_from' onChange={(e) => setValues({...values,[e.target.name] : e.target.value})} />
                        <label htmlFor="initial_from">مبدأى من</label>
                    </span>
                    <span className="p-float-label mx-4 my-2" style={{direction:"ltr"}}>
                    <InputText id="intial_to" value={values.intial_to} name="intial_to" onChange={(e) => setValues({...values,[e.target.name] : e.target.value})} />
                        <label htmlFor="intial_to">مبدأى الى</label>
                    </span>
                    <span className="p-float-label mx-4 my-2" style={{direction:"ltr"}}>
                    <InputText id="final_from" value={values.final_from} name='final_from' onChange={(e) => setValues({...values,[e.target.name] : e.target.value})} />
                        <label htmlFor="final_from">نهائى من</label>
                    </span>
                    <span className="p-float-label mx-4 my-2" style={{direction:"ltr"}}>
                    <InputText id="final_to" value={values.final_to} name="final_to" onChange={(e) => setValues({...values,[e.target.name] : e.target.value})} />
                        <label htmlFor="final_to">نهائى الى</label>
                    </span>
                </div>
            </div>
        </main>
    )
}