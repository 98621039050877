import axios from "axios";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { baseUrl } from "../../../Api/Api";
import Cookie from 'cookie-universal'
import { Tag } from 'primereact/tag';
export default function EmpData(props) {

    const cookie = Cookie();

    const token = cookie.get("pe")

    const [fieldValue, setFieldValue] = useState(props.value);
    const [approve, setApprove] = useState(false)

    const handleKeyDown = async (e) => {
        if (e.key == 'Enter') {
            await axios.post(`${baseUrl}/updateEmpField/${e.target.id}/${e.target.value}/${props.empId}`,null,
                { headers: { Authorization: `Bearer ${token}` } }
            ).then(() => setApprove(true))
        }
    }

    return (
        <div className="d-flex flex-column gap-2 mx-2 my-2">
            <label htmlFor={props.name}>{props.title}</label>
            <div className="d-flex flex-row">
                <InputText
                    onKeyDown={(e) => handleKeyDown(e)}
                    id={props.name}
                    style={props.style}
                    value={fieldValue} disabled={props.disabled}
                    onChange={(e) => setFieldValue(e.value)}
                />
                <Tag className={approve ? "mx-2 d-block" : "mx-2 d-none"} icon="pi pi-check" severity="success"
                    style={{ maxHeight: "30px", borderRadius: "50%" }}></Tag>
            </div>
        </div>
    )
}