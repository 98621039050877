import { Outlet,Navigate, useNavigate} from "react-router-dom";
import Cookie from 'cookie-universal'
import { useEffect, useState } from "react";
import axios from "axios";
import { USER, baseUrl } from "../../Api/Api";
import Loading from "../../components/Loading";
import Error403 from "../../components/Error403";
export default function RequireAuth({allowedRole}) {
    const [user,setUser] = useState("");
    const navigate = useNavigate();
    useEffect(() => {
        axios.get(`${baseUrl}/${USER}`,{headers:{Authorization : `Bearer ${token}`}})
        .then(data => setUser(data.data)).catch(() => navigate("/login",{replace:true}))
    },[])
    const cookie= Cookie();
    const token = cookie.get("pe");
    return token ? 
    user === "" ?
    (<Loading />) : allowedRole.includes(user.role) ?
    (<Outlet />) : (<Error403 />) : (<Navigate to={"/login"} replace={true}/>) ;
}