import Header from "../frontend/Header";
import { Card } from 'primereact/card';
import axios from "axios";
import Cookie from 'cookie-universal';
import React,{ useEffect, useState,useRef } from "react";
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import {baseUrl} from "../../Api/Api";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
export default function EvaluationsQuery() {
    const [sectors, setSectors] = useState([]);
    const [sector, setSector] = useState(null)
    const cookie = Cookie();
    const token = cookie.get("pe");
    const dd = useRef(null);
    const [loading, setLoading] = useState(false);
    const [selectedCity, setSelectedCity] = useState(null);
    const [evaluated,setEvaluated] = useState(false)
    const [data,setData] = useState(null)
    const cities = [
        { name: "الكل" },
        { name: "طما" },
        { name: "طهطا" },
        { name: "المراغة" },
        { name: "جهينة" },
        { name: "سوهاج" },
        { name: "ساقلته" },
        { name: "المنشاه" },
        { name: "أخميم" },
        { name: "جرجا" },
        { name: "البلينا" },
        { name: "دار السلام" },
        { name: "الديوان" },
        { name: "ملحق الديوان" },
    ];

    useEffect(() => {
        async function getData() {
            await axios.get(`${baseUrl}/statisticsData`, { headers: { Authorization: `Bearer ${token}` } }).then((data) => {
                    setSectors([{ sector: "الكل" }, ...data.data.sector])
                }
            )
        }
        getData()
    },[])

    const getData = async () => {
       try {
           setLoading(true)
           let theCity = "";
           if ( selectedCity === null ) {
               theCity = "الكل";
           }else {
               theCity = selectedCity.name;
           }
           let theSector = "";
           if ( sector === null ) {
               theSector = "الكل";
           }else {
               theSector = sector.sector;
           }
           if ( evaluated === false ) {
               await axios.get(`${baseUrl}/getEvaluates/${theCity}/${theSector}`,
                   { headers: { Authorization: `Bearer ${token}` } }).then((dt) => {
                       setData(dt.data.evaluations)
                   }
               )
           }else {
               await axios.get(`${baseUrl}/getEvaluates0/${theCity}/${theSector}`,
                   { headers: { Authorization: `Bearer ${token}` } }).then((dt) => {
                       setData(dt.data.evaluations)
                   }
               )
           }
       }catch (ex) {
           console.log(ex.message)
        }finally {
           setLoading(false)
       }
    }
    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'evaluation');
        });
    };
    const saveAsExcelFile = (buffer, fileName) => {
            import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
            });
            module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
    });
    }
    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
        </div>
    );
    return (
        <>
            <div style={{direction:"rtl"}}>
                <Header />
                <Card title={"استعلام التقارير"} style={{marginTop:"100px"}} className={"mx-5"}>
                    <div className="row p-2">

                        <div className="col-sm-3">
                            <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name"
                                      placeholder="اختر فرع" className="w-100 md:w-14rem" />
                        </div>
                        <div className="col-sm-3">
                            <Dropdown value={sector} onChange={(e) => setSector(e.value)} options={sectors} optionLabel="sector"
                                      placeholder="اختر قطاع" className="w-100 md:w-14rem mt-sm-0 mt-2" />
                        </div>
                        <div className="col-sm-3 d-flex ">
                            <div className="d-flex align-items-center">
                                <Checkbox inputId="eval" name="eval" value="eval" onChange={((e) => setEvaluated(e.checked))} checked={evaluated} />
                                <label htmlFor="eval" className="mx-4 fs-5">الكل</label>
                            </div>
                            <Button onClick={getData} className="rounded mt-sm-0 mt-2" icon="pi pi-search" loading={loading} />
                        </div>
                    </div>
                    <DataTable loading={loading} ref={dd} value={data} stripedRows tableStyle={{ minWidth: '50rem',textAlign:"right" }}
                               paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}  header={header}
                    >
                        <Column field="[كود الموظف]" header="الكود" style={{textAlign:"right"}}></Column>
                        <Column field="[اسم الموظف]" header="الاسم" style={{textAlign:"right"}}></Column>
                        <Column field="[الوظيفة]" header="الوظيفة" style={{textAlign:"right"}}></Column>
                        <Column field="[القطاع]" header="القطاع" style={{textAlign:"right"}}></Column>
                        <Column field="[الفرع]" header="الفرع" style={{textAlign:"right"}}></Column>
                        <Column field="[الدرجة المبدأي]" header="درجة مبدأى" style={{textAlign:"right"}}></Column>
                        <Column field="[التقدير المبدأى]" header="تقدير مبدأى" style={{textAlign:"right"}}></Column>
                        <Column field="[الدرجة النهائى]" header="درجة نهائى" style={{textAlign:"right"}}></Column>
                        <Column field="[التقدير النهائى]" header="تقدير نهائى" style={{textAlign:"right"}}></Column>
                    </DataTable>
                </Card>
            </div>
        </>
    )
}