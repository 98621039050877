import axios from "axios";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { EMPLOYEES, baseUrl } from "../../../Api/Api";
import Cookie from 'cookie-universal'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { useNavigate } from "react-router-dom";
export default function Employees() {
    const navigate = useNavigate();
    const show = useOutletContext();
    const [emps,setEmps] = useState([]);
    const cookie = Cookie();
    const token = cookie.get("pe")
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },

    });
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };
    useEffect(() => {
       async function getData() {
            await axios.get(`${baseUrl}/${EMPLOYEES}`,{headers : {Authorization : `Bearer ${token}`}})
            .then((data) => setEmps(data.data)).catch(() => console.log("error on get data"))
        }
        getData()
    },[])
    const operationsTemplate = (item) => {
        return (
            <>
                <Button onClick={() => navigate('/dashboard/employee',{state:{employee : item}})} icon="pi pi-eye" size="small" text className="rounded"/>

            </>
        )
    }
    const header = renderHeader();
    return (
        <>
            <main id="main" className={show ? "mainNarrow" : "mainWide"} style={{ direction: "rtl" }}>
                <div className="pagetitle">
                    <h1>بيانات العاملين</h1>

                </div>
                <DataTable size="small" filters={filters} paginator rows={7} dataKey="id"  filterDisplay="row"
                globalFilterFields={['name', 'code']} header={header} emptyMessage="لا يوجد موظفين"
                value={emps} tableStyle={{ minWidth: '50rem',textAlign:"right" }}>
                    <Column field="code" header="الكود" style={{ textAlign:"right"}}></Column>
                    <Column field="name" header="الاسم" style={{ textAlign:"right"}}></Column>
                    <Column field="job" header="الوظيفة" style={{ textAlign:"right"}}></Column>
                    <Column field="jobname" header="المجموعة" style={{ textAlign:"right"}}></Column>
                    <Column field="branch" header="الفرع" style={{ textAlign:"right"}}></Column>
                    <Column header="عمليات" body={operationsTemplate} style={{ textAlign:"right"}}></Column>
                </DataTable>
            </main>
        </>
    )
}