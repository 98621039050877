import { useLocation } from "react-router-dom";
import Header from '../frontend/Header'
import { useEffect, useState, useRef } from "react";
import Loading from '../../components/Loading';
import axios from "axios";
import { REPORT01, SAVEREPORT01, baseUrl } from "../../Api/Api";
import Cookie from 'cookie-universal'
import { Fieldset } from 'primereact/fieldset';
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Message } from 'primereact/message';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useNavigate } from "react-router-dom";
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { Chip } from 'primereact/chip';
export default function Evaluate01() {
    const toast = useRef(null);
    const nav = useNavigate();
    const [values, setValues] = useState({});
    const [selectValue, setSv] = useState(null)
    const [loading, setLoading] = useState(false);
    const location = useLocation()
    const [formData, setFormData] = useState([]);
    const cookie = Cookie();
    const token = cookie.get("pe");
    const [total, setTotal] = useState(0)
    const [evalute, setEval] = useState('')
    const [ew, setEw] = useState('-')
    const [allow90, setAllow90] = useState('')
    const [oldValues, setOldValues] = useState(null)
    const showError = (detail) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: detail, life: 3000 });
    }
    const handleSetEw = (e) => {
        if (!ew.includes(e.target.innerText)) {
            setEw(ew + ' - ' + e.target.innerText)
        }

    }
    useEffect(() => {
        setTotal(0)

        async function getData() {
            setLoading(true)

            await axios.get(`${baseUrl}/${REPORT01}/${location.state.id}/${location.state.emp.id}`,
                { headers: { Authorization: `Bearer ${token}` } }).then((dt) => {
                    setFormData(dt.data);
                    let fData = [];
                    fData = dt.data;

                    if (location.state.edit === false) {
                        let copy = {}
                        dt.data.terms.map((item, index) => {
                            copy['term-' + item.id] = { id: item.id, value: null }
                        })
                        setValues(copy)
                        
                    }

                    fData.gzs.map((item) => {
                        if (item.impact === 1) {
                            setAllow90('جزاء')
                        }
                    })
                    fData.train.map((item) => {
                        if (item.impact === 1) {
                            setAllow90('عدم اجتياز دورة')
                        }
                    })

                }).catch((err) => console.log(err))


        }
        async function getOldValuesData() {
            if (location.state.edit === true) {
                await axios.get(`${baseUrl}/reportInit01/${location.state.id}/${location.state.emp.id}`,
                    { headers: { Authorization: `Bearer ${token}` } }).then((data) => {

                        //  setOldValues(data.data.init_evaluate)
                        setTotal(data.data.init_evaluate.degree)
                        setEval(data.data.init_evaluate.evaluate)
                        let copy = {}
                        data.data.init_result.map(itm => {
                            copy['term-' + itm.form_terms_id] = { id: itm.form_terms_id, value: itm.result }

                        })
                        setValues(copy)

                    })
            }
        }
        getOldValuesData();
        getData();

        setLoading(false)
    }, [])

    const changeValue = (e, id) => {
        const copy = values;

        copy['term-' + id].value = e.target.value


        let tt = 0;
        Object.values(copy).map((item) => {
            tt = tt + item.value;
        })

        if (tt < 50) {
            setEval("ضعيف")
        } if (tt >= 50 && tt < 65) {
            setEval("متوسط")
        } if (tt >= 65 && tt < 80) {
            setEval("جيد")
        } if (tt >= 80 && tt < 90) {
            setEval("جيد جدا")
        } if (tt >= 90) {
            setEval("ممتاز")
        }
        setValues(copy)
        setTotal(tt)
        setSv(e.target.value)





    }
    const saveReport = async () => {
        let hasNull = false;
        Object.values(values).map((item) => {
            if (item.value === null) {
                hasNull = true;
            }
        })
        if (hasNull) {
            showError('لا يمكن ترك عنصر من عناصر التقييم فارغ')
            return
        }
        if (total >= 90) {
            if (allow90 !== '') {
                showError('لا يمكن تقدير مرتبة كفائته بمرتبة ممتاز بسبب ' + allow90)
                return
            }
        }
        if (location.state.emp.evaluate_limit > 0) {
            if (total < location.state.emp.evaluate_limit) {
                showError(`تقرير الكفائة حكماً لا يقل عن : ${location.state.emp.evaluate_limit_txt} - ${location.state.emp.evaluate_limit}`)
                return
            }

        }
        // عناصر التميز او الضعف
        if (total >= 90 || total <= 50) {
            if (ew === '-' || ew === '') {
                showError('يجب كتابة عناصر التميز او الضعف')
                return
            }
        }
        setLoading(true)
        let eww = '';
        if (ew === '') {
            eww = '-'
        } else {
            eww = ew
        }
        if (location.state.edit === true) {
            await axios.post(`${baseUrl}/editReport01/${location.state.emp.id}/${formData.evaluation.id}/${eww}`,
                values
                , { headers: { Authorization: `Bearer ${token}` } }).then(() => nav("/evaluation")).catch((error) => console.log(error))
        } else {
            await axios.post(`${baseUrl}/${SAVEREPORT01}/${location.state.emp.id}/${formData.evaluation.id}/${eww}`,
                values
                , { headers: { Authorization: `Bearer ${token}` } }).then(() => nav("/evaluation")).catch((error) => console.log(error))
        }

        setLoading(false)
    }
    return (
        <div style={{ direction: "rtl" }}>
            <Toast ref={toast} position="center" />
            {loading && <Loading />}
            <Header />
            {formData.form &&
                <div style={{ maxWidth: "1000px", margin: "0 auto" }}>
                    <h5 style={{ marginTop: "100px", textAlign: "center" }} className="text-secondary">
                        {formData.form.name} عن الفترة من {formData.evaluation.from} الى {formData.evaluation.tw}</h5>
                    <div className="m-3">
                        <Fieldset legend="بيانات الموظف">
                            <div className="d-flex flex-column">
                                <div className="d-flex flex-sm-row flex-column">
                                    <div className="d-flex flex-row mb-1" style={{ minWidth: "50%" }}>
                                        <div className="p-2 mx-2 shadow-sm rounded bg-light" style={{ minWidth: "120px" }}>الكـــود</div>
                                        <div className="p-2 border-bottom fs-6">{location.state.emp.code}</div>
                                    </div>
                                    <div className="d-flex flex-column flex-sm-row mb-1">
                                        <div className="p-2 mx-2 shadow-sm rounded bg-light" style={{ minWidth: "120px" }}>الاســــم</div>
                                        <div className="p-2 border-bottom" style={{ minWidth: "250px" }}>{location.state.emp.name}</div>
                                    </div>
                                </div>
                                <div className="d-flex flex-sm-row flex-column">
                                    <div className="d-flex flex-column flex-sm-row  mb-1" style={{ minWidth: "50%" }}>
                                        <div className="p-2 mx-2  shadow-sm rounded bg-light" style={{ minWidth: "120px" }}>المجموعة النوعية</div>
                                        <div className="p-2 border-bottom" style={{ minWidth: "250px" }}>{location.state.emp.jobname}</div>
                                    </div>
                                    <div className="d-flex flex-column flex-sm-row mb-1">
                                        <div className="p-2 mx-2 shadow-sm rounded bg-light" style={{ minWidth: "120px" }}>الوظيفة</div>
                                        <div className="p-2 border-bottom" style={{ minWidth: "250px" }}>{location.state.emp.job}</div>
                                    </div>
                                </div>
                                <div className="d-flex flex-sm-row flex-column">
                                    <div className="d-flex flex-column flex-sm-row mb-1" style={{ minWidth: "50%" }}>
                                        <div className="p-2 mx-2 shadow-sm rounded bg-light" style={{ minWidth: "120px" }}>الدرجة</div>
                                        <div className="p-2 border-bottom" style={{ minWidth: "250px" }}>{location.state.emp.employeeDegree}</div>
                                    </div>
                                    <div className="d-flex flex-column flex-sm-row mb-1">
                                        <div className="p-2 mx-2 shadow-sm rounded bg-light" style={{ minWidth: "120px" }}>تاريخها</div>
                                        <div className="p-2 border-bottom" style={{ minWidth: "250px" }}>{location.state.emp.degreedate}</div>
                                    </div>
                                </div>
                                <div className="d-flex flex-sm-row flex-column">
                                    <div className="d-flex flex-column flex-sm-row mb-1" style={{ minWidth: "50%" }}>
                                        <div className="p-2 mx-2 shadow-sm rounded bg-light" style={{ minWidth: "120px" }}>الفرع</div>
                                        <div className="p-2 border-bottom" style={{ minWidth: "250px" }}>{location.state.emp.branch}</div>
                                    </div>
                                    <div className="d-flex flex-column flex-sm-row mb-1">
                                        <div className="p-2 mx-2 shadow-sm rounded bg-light" style={{ minWidth: "120px" }}>جهة العمل</div>
                                        <div className="p-2 border-bottom" style={{ minWidth: "250px" }}>{location.state.emp.sec}</div>
                                    </div>
                                </div>
                                <div className="d-flex flex-sm-row flex-column">
                                    <div className="d-flex flex-row mb-1" style={{ minWidth: "50%" }}>
                                        <div className="p-2 mx-2 shadow-sm rounded bg-light">2021</div>
                                        <div className="p-2 border-bottom">{location.state.emp.report01}</div>
                                    </div>
                                    <div className="d-flex flex-row mb-1">
                                        <div className="p-2 mx-2 shadow-sm rounded bg-light">2022</div>
                                        <div className="p-2 border-bottom">{location.state.emp.report02}</div>
                                    </div>
                                </div>
                            </div>
                        </Fieldset>
                        <Accordion activeIndex={0} className="mt-2">
                            <AccordionTab header={' الجزاءات ( ' + formData.gzs.length + " ) "}>
                                {formData.gzs.map(item => (
                                    <>
                                        {item.impact === 1 ?
                                            <Message className="mt-1" severity="error" text={item.desc} /> :
                                            <Message className="mt-1" severity="warn" text={item.desc} />
                                        }
                                        <br />
                                    </>
                                ))}
                            </AccordionTab>
                            <AccordionTab header={' الدورات التدريبية ( ' + formData.train.length + " ) "}>
                                {formData.train.map(item => (
                                    <>
                                        {item.impact === 1 ?
                                            <Message className="mt-1" severity="error" text={item.desc} /> :
                                            <Message className="mt-1" severity="warn" text={item.desc} />
                                        }
                                        <br />
                                    </>
                                ))}
                            </AccordionTab>
                            <AccordionTab header={' اجازات بدون اذن ( ' + formData.agzb.length + " ) "}>
                                {formData.agzb.map(item => (
                                    <>
                                        {item.impact === 1 ?
                                            <Message className="mt-1" severity="error" text={item.desc} /> :
                                            <Message className="mt-1" severity="warn" text={item.desc} />
                                        }
                                        <br />
                                    </>
                                ))}
                            </AccordionTab>
                            <AccordionTab header={' علاوات - خطابات شكر( ' + formData.other.length + " ) "}>
                                {formData.other.map(item => (
                                    <>
                                        <Message className="mt-1" severity="warn" text={item.desc} />
                                        <br />
                                    </>
                                ))}
                            </AccordionTab>
                            <AccordionTab header={'عناصر التميز او الضعف'}>
                                <div className="d-flex">
                                    <Button label="يؤدى عمله على اكمل وجه" severity="success" outlined className="rounded mx-1 my-1" onClick={(e) => handleSetEw(e)} />
                                    <Button label="يؤدى عمله باتقان" severity="success" outlined className="rounded mx-1 my-1" onClick={(e) => handleSetEw(e)} />
                                    <Button label="لا يؤدى عمله على اكمل وجه" severity="danger" outlined className="rounded mx-1 my-1" onClick={(e) => handleSetEw(e)} />
                                    <Button label="لا يؤدى عمله باتقان" severity="danger" outlined className="rounded mx-1 my-1" onClick={(e) => handleSetEw(e)} />

                                </div>
                                <InputTextarea value={ew} onChange={(e) => setEw(e.target.value)} rows={5} cols={30} style={{ width: "100%" }} />
                            </AccordionTab>
                        </Accordion>
                        <Card title="التقييم" className="mt-2">
                            <div className="d-flex">
                                {location.state.emp.evaluate_limit > 0 &&
                                    <Message severity="info" 
                                    text={`تقرير الكفائة حكماً لا يقل عن : ${location.state.emp.evaluate_limit_txt} - ${location.state.emp.evaluate_limit}`} />
                                }
                            </div>
                            <div className="d-flex flex-column my-2  justify-content-start">
                                {formData.terms.map((item, index) =>
                                (
                                    <div className="d-flex flex-sm-row flex-column mb-2 border-bottom pb-2">
                                        <div className="p-2 mx-2 shadow-sm rounded bg-ligh my-sm-0 my-1 text-info"
                                            style={{ minWidth: "5%", textAlign: "center", maxWidth: "50px", maxHeight: "40px" }}>{index + 1}</div>
                                        <div className="p-2 border border-light-subtle mx-2 rounded" style={{ minWidth: "75%" }}>{item.name}</div>
                                        <div className="d-flex flex-row" style={{ minWidth: "18%" }}>
                                            <div className="p-2 border mx-2 rounded text-center my-sm-0 my-1 border-primary-subtle"
                                                style={{ minWidth: "30%", maxHeight: "40px" }}>{item.value}</div>
                                            <Dropdown placeholder="0" required
                                                value={values['term-' + item.id] && values['term-' + item.id]['value']}
                                                name={'term-' + item.id} options={[...Array(item.value + 1).keys()]}
                                                style={{ minWidth: "60%", maxHeight: "40px" }}
                                                onChange={(e) => changeValue(e, item.id)}
                                                className=" my-sm-0 my-1 border-success-subtle" />
                                        </div>
                                    </div>
                                )
                                )}
                            </div>
                            <div className="d-flex flex-column">
                                <div className="d-flex flex-sm-row flex-column mb-2 border-bottom pb-2">
                                    <div className="p-2 mx-2" style={{ minWidth: "5%" }}></div>
                                    <div className="p-2 mx-2" style={{ minWidth: "75%" }}></div>
                                    <div className="d-flex flex-row">
                                        <div className="p-2 border mx-2 rounded text-center  text-white" style={{ minWidth: "40%", backgroundColor: "dimgray" }}>100</div>
                                        <div className="p-2 border mx-2 rounded text-center text-success border-success outline" style={{ minWidth: "55%" }}>
                                            {total}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                <div className="d-flex flex-sm-row flex-column mb-2 border-bottom pb-2">
                                    <div className="p-2 mx-2" style={{ minWidth: "5%" }}></div>
                                    <div className="p-2 mx-2" style={{ minWidth: "75%" }}></div>
                                    <div className="d-flex flex-row">
                                        <div className="p-2 mx-2 rounded text-center" style={{ minWidth: "15%", fontSize: "20px" }}><Chip label={evalute} /></div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <div className="d-flex flex-row p-3 mt-1">
                            <Button icon="pi pi-save"
                                label={location.state.edit === true ? "تعديل التقييم" : "تقييم"}
                                severity="success" className="rounded" raised onClick={saveReport} />
                        </div>

                    </div>
                </div>
            }
        </div>
    );
}