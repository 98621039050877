import hero from '../../images/home/evaluation_form.png';
import hero1 from '../../images/home/statistics.png';
import hero2 from '../../images/home/employee_statistics.png';
import hero3 from '../../images/home/props-financial-reports.png';
import hero4 from '../../images/home/rank.png';
import { Card } from 'primereact/card';
import { useNavigate } from "react-router-dom";
import Cookie from 'cookie-universal'
import axios from "axios";
import {useEffect, useState} from "react";
import {baseUrl, USER} from "../../Api/Api";
export default function Hero() {
    const [user, setUser] = useState("");
    useEffect(() => {
        axios.get(`${baseUrl}/${USER}`, { headers: { Authorization: `Bearer ${token}` } })
            .then(data => {
                setUser(data.data)
            }).catch(() => console.log("not logged in"))
    }, [])
    const cookie = Cookie();
    const token = cookie.get("pe");
    const nav = useNavigate();
    const header = (
        <div style={{ height:"150px",width:"200px",textAlign:"center"}}>
            <img alt="Card" src={hero} style={{ height:"150px",width:"150px"}}/>
        </div>
    );
    const header1 = (
        <div style={{ height:"150px",width:"200px",textAlign:"center"}}>
            <img alt="Card" src={hero1} style={{ height:"130px",width:"150px"}}/>
        </div>
    );
    const header2 = (
        <div style={{ height:"150px",width:"200px",textAlign:"center"}}>
            <img alt="Card" src={hero2} style={{ height:"130px",width:"150px"}}/>
        </div>
    );
    const header3 = (
        <div style={{ height:"150px",width:"200px",textAlign:"center"}} className="pt-4">
            <img alt="Card" src={hero3} style={{ height:"100px",width:"150px"}}/>
        </div>
    );
    const header4 = (
        <div style={{ height:"150px",width:"200px",textAlign:"center"}} className="pt-4">
            <img alt="Card" src={hero4} style={{ height:"100px",width:"150px"}}/>
        </div>
    );
    return <>
        <div className="hero">
            <div className="hero-background"></div>
        </div>
        <div className="hero-body">
            <div className="row">
                <div className="d-flex align-items-top justify-content-center">
                    <h1 className='text-light text-center' style={{ fontWeight: "700", fontSize: "55px" }}>
                        المنصة الرقمية لتقييم الاداء
                        <br />
                        <span style={{ fontSize: "25px", fontWeight: "600" }}>ادارة اداء الموظفين وتحفيزهم على الانتاجية</span>
                    </h1>
                </div>
                <div className='d-flex flex-md-row flex-column align-items-center justify-content-between mx-auto mt-4' style={{ maxWidth:"1200px"}}>
                    <div className='mx-3 mt-2'>
                        <Card title="التقييم" subTitle="تقييم اداء الموظفين" header={header} style={{cursor:"pointer"}} onClick={() => nav("/evaluation")}>

                        </Card>
                    </div>
                    <div className='mx-3 mt-2'>
                    <Card title="احصائيات " subTitle="احصائيات التقييم" header={header1} style={{cursor:"pointer"}}
                          onClick={() => nav("/statistics")}>

                    </Card>
                </div>
                    <div className='mx-3 mt-2'>
                        <Card title="التقارير" subTitle="تقارير الموظفين" header={header2} style={{cursor:"pointer"}} onClick={() => nav("/employeesF")}>

                        </Card>
                    </div>
                    <div className='mx-3 mt-2'>
                        <Card title="الاستعلام" subTitle="الاستعلام" header={header4} style={{cursor:"pointer"}} onClick={() => nav("/evaluationsQuery")}>

                        </Card>
                    </div>
                    <div className='mx-3 mt-2'>
                        <Card title="طباعة" subTitle="طباعة نماذج التقييم" header={header3} style={{cursor:"pointer"}} onClick={() => nav("/print")}>

                        </Card>
                    </div>
                </div>
            </div>
            <div className='hero-body-section'>
            </div>
        </div>

    </>
}