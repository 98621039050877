import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import Cookie from 'cookie-universal'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { LOGOUT, USER, baseUrl } from "../../Api/Api";
import logo from '../../images/logo.png'
export default function Header() {

    const [scrolled, setScrolled] = useState(false);
    const [user, setUser] = useState("");
    const nav = useNavigate();
    const [show, setShow] = useState(false);

    const listenScrollEvent = e => {
        if (window.scrollY > 50) {
            setScrolled(true)
        } else {
            setScrolled(false)
        }
    }

    const handleShow = () => {

        setShow(!show);
    }
    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent)
        axios.get(`${baseUrl}/${USER}`, { headers: { Authorization: `Bearer ${token}` } })
            .then(data => {
                setUser(data.data)
            }).catch(() => console.log("not logged in"))
    }, [])
    const cookie = Cookie();
    const token = cookie.get("pe");
    const handleLogOut = async () => {
        try {
            await axios.get(`${baseUrl}/${LOGOUT}`, { headers: { Authorization: `Bearer ${token}` } })
                .then(() => {
                    cookie.remove("pe");
                    nav("/", { replace: true })
                })
        } catch (err) {
            console.log(err)
        }
    }
    return (
        <>
            <header id="header" className={scrolled ? "fixed-top shadow-sm header-scroll" : "fixed-top shadow-sm color-not-scroll"} >
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div className="logo d-flex ">
                        <h1><a href="index.html" className=
                            {scrolled ? "" : "color-not-scroll"}>تقييم الاداء</a></h1>
                        <a href="/"><img src={logo} alt="" style={{ width: "50px", height: "50px", maxHeight: "50px" }} /></a>
                    </div>
                    <nav id="navbar" className={show ? "navbar navbar-mobile" : "navbar"}>
                        <ul className={show ? "navbar-mobile navbar" : "navbar"}>
                            <li><a className=
                                {scrolled ? "scrollto" : "scrollto  color-not-scroll"}
                                onClick={() => nav("/")} href="">الرئيسية</a></li>
                            {token && user !== "" && (user.role === '1995' || user.role === '1998') &&
                                <>
                                <li><a className={scrolled ? " scrollto" : " scrollto  color-not-scroll"} onClick={() => nav("/print")} href="">طباعة</a></li>
                                <li><a className={scrolled ? " scrollto" : " scrollto  color-not-scroll"} onClick={() => nav("/employeesF")} href="">التقارير</a></li>
                                <li><a className={scrolled ? " scrollto" : " scrollto  color-not-scroll"} onClick={() => nav("/statistics")} href="">احصائيات</a></li>
                                </>
                                
                                }

                            {token && user !== "" && (user.role === '1995' || user.role === '1997') && <li><a className={scrolled ? " scrollto" : " scrollto  color-not-scroll"} onClick={() => nav("/evaluation")} href="">تقييم</a></li>}
                           
                            {token && user !== "" && user.role === '1995' && <li><a className={scrolled ? " scrollto p-2" : "p-2  scrollto  color-not-scroll"}
                                onClick={() => nav("/dashboard")} href="">لوحة التحكم</a></li>}
                           
                            {token && user !== "" ?
                                <>
                                    <li><a className=
                                        {scrolled ? "scrollto mx-2" : "scrollto  color-not-scroll mx-2"}
                                        onClick={() => nav("/changePassword")} href="">تغير كلمة السر</a></li>
                                    <li><span className={scrolled ? "scrollto mx-2" : "scrollto  color-not-scroll mx-2"} style={{ cursor: "pointer" }} onClick={handleLogOut}>
                                        <FontAwesomeIcon icon={faSignOut} />
                                    </span></li>
                                </>

                                :
                                <li><a className={scrolled ? "getstarted scrollto" : "getstarted scrollto  color-not-scroll"} onClick={() => nav("login")} href="">تسجيل الدخول</a></li>
                            }
                            
                        </ul>
                        <FontAwesomeIcon icon={faBars} className="mobile-nav-toggle" onClick={handleShow} />
                    </nav>
                </div>
            </header>
        </>
    )
}