import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Header(props) {
    return <>
        <header id="header" className="header fixed-top d-flex align-items-center">
            <div className="d-flex align-items-center justify-content-between">
                <a href="index.html" className="logo d-flex align-items-center">
                    <img src="assets/img/logo.png" alt="" />
                    <span className="d-none d-lg-block">تقييم الاداء</span>
                </a>
                <FontAwesomeIcon icon={faBars} className="toggle-sidebar-btn" onClick={() => props.show()}/>
            </div>
        </header>
    </>
}