import { useEffect, useState } from 'react';
import Header from "../frontend/Header";
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
import {  baseUrl } from '../../Api/Api';
import Cookie from 'cookie-universal';
import Loading from '../../components/Loading'
import { Button } from 'primereact/button';
import { useNavigate} from "react-router-dom";
export default function Print() {
    const [loading, setLoading] = useState(false);
    const cookie= Cookie();
    const token = cookie.get("pe");
    const nav = useNavigate();
    const [branchs,setBranchs] = useState([
        {name : "طما"},
        {name : "طهطا"},
        {name : "المراغة"},
        {name : "جهينة"},
        {name : "سوهاج"},
        {name : "ساقلته"},
        {name : "المنشاه"},
        {name : "اخميم"},
        {name : "جرجا"},
        {name : "البلينا"},
        {name : "دار السلام"},
        {name : "الديوان"},
        {name : "ملحق الديوان"},
    ])
    const [branch,setBranch] = useState(null)
    const openReports = () => {
        if ( branch == null) {
            alert("رجاء اختر فرع")
            
        }else {
            nav('/printReport',{state : {branch:branch}})
        }
    }
    return (
        <div style={{direction:"rtl"}}>
            {loading && <Loading />}
            <Header />
            <div className='d-flexs card' style={{margin:"150px auto",maxWidth:"500px"}}>
                <div className="card-header">طباعة التقارير</div>
                    <div className="card-body">
                    <Dropdown value={branch} onChange={(e) => setBranch(e.value)} options={branchs} optionLabel="name" 
                    placeholder="اختر فرع" className="w-full md:w-14rem" />
                    <Button icon="pi pi-table" outlined severity="success" aria-label="Bookmark"
                     className='rounded-circle mx-2 mt-3' 
                     onClick={openReports}
                     />
                    </div>
            </div>
        </div>
    )
}