import { useEffect, useState } from "react";
import Header from "../frontend/Header";
import axios from "axios";
import Cookie from 'cookie-universal'
import { EVALUATION, baseUrl } from "../../Api/Api";
import { Chart } from 'primereact/chart';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
export default function Statistics() {
    const [jobnames, setJobnames] = useState([]);
    const [jobname, setJobname] = useState(null)
    const [sectors, setSectors] = useState([]);
    const [sector, setSector] = useState(null)
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    const [evaluateData, setEvaluateData] = useState([]);

    const [evaluateKind, setEvaluateKind] = useState({ name: "تقييم نهائى" });

    const [evaluation, setEvalution] = useState("");
    const cookie = Cookie();
    const token = cookie.get("pe");

    const [loading, setLoading] = useState(false);
    const [statistics, setStatistics] = useState({
        a: 0,
        b: 0,
        c: 0,
        d: 0,
        e: 0
    })
    const load = () => {
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
        }, 2000);
    };
    useEffect(() => {
        async function getEvaluation() {
            await axios.get(`${baseUrl}/${EVALUATION}`, { headers: { Authorization: `Bearer ${token}` } }).then((data) => {
                setEvalution(`${data.data.name}`)
            });
        }

        async function getData() {
            await axios.get(`${baseUrl}/statisticsData`, { headers: { Authorization: `Bearer ${token}` } }).then((data) => {

                let jobnames = ([{ jobname: "الكل" }, ...data.data.jobname])

                setJobnames(jobnames)
                setSectors([{ sector: "الكل" }, ...data.data.sector])
            }
            )
        }

        async function getEvaluationsData() {
            await axios.get(`${baseUrl}/getEvaluationsData`, { headers: { Authorization: `Bearer ${token}` } }).then((data) => {
                setEvaluateData(data.data.dataF)

            }
            )
        }
        getEvaluation()
        getData()
        getEvaluationsData()
        handelChartData()


    }, [])
    const [selectedCity, setSelectedCity] = useState(null);
    const cities = [
        { name: "الكل" },
        { name: "طما" },
        { name: "طهطا" },
        { name: "المراغة" },
        { name: "جهينة" },
        { name: "سوهاج" },
        { name: "ساقلته" },
        { name: "المنشاه" },
        { name: "اخميم" },
        { name: "جرجا" },
        { name: "البلينا" },
        { name: "دار السلام" },
        { name: "الديوان" },
        { name: "ملحق الديوان" },
    ];
    const handelChartData = () => {
        // chart data
        let a = 0;
        let b = 0;
        let c = 0;
        let d = 0;
        let e = 0;
        let evaluateDataFiltered = evaluateData;
        // filter branch
        if (selectedCity != null) {
            if (selectedCity.name !== 'الكل') {
                evaluateDataFiltered = evaluateDataFiltered.filter((item) => item.branch === selectedCity.name);
            }

        }
        if (jobname != null) {
            if (jobname.jobname !== 'الكل') {
                evaluateDataFiltered = evaluateDataFiltered.filter((item) => item.jobname.includes(jobname.jobname));
            }

        }
        if (sector != null) {
            if (sector.sector !== 'الكل') {
                evaluateDataFiltered = evaluateDataFiltered.filter((item) => item.sector === sector.sector);
            }

        }

        if (evaluateKind.name === 'تقييم مبدأى') {
            a = evaluateDataFiltered.filter((item) => item.evaluate.includes('ممتاز')).length
            b = evaluateDataFiltered.filter((item) => item.evaluate.includes('جدا')).length
            c = evaluateDataFiltered.filter((item) => item.evaluate === 'جيد').length
            d = evaluateDataFiltered.filter((item) => item.evaluate.includes('متوسط')).length
            e = evaluateDataFiltered.filter((item) => item.evaluate.includes('ضعيف')).length
        } else {
            a = evaluateDataFiltered.filter((item) => item.evaluate_final.includes('ممتاز')).length
            b = evaluateDataFiltered.filter((item) => item.evaluate_final.includes('جدا')).length
            c = evaluateDataFiltered.filter((item) => item.evaluate_final === 'جيد').length
            d = evaluateDataFiltered.filter((item) => item.evaluate_final.includes('متوسط')).length
            e = evaluateDataFiltered.filter((item) => item.evaluate_final.includes('ضعيف')).length
        }

        setStatistics({ a, b, c, d, e })
        const data = {
            labels: ['ممتاز', 'جيد جدا', 'جيد', 'متوسط', 'ضعيف'],
            datasets: [
                {
                    label: 'التقييمات',
                    data: [a, b, c, d, e],
                    backgroundColor: [
                        '#00c803',
                        'rgb(183, 255, 0)',
                        '#e2ff51',
                        '#ffd000',
                        '#ff8800'
                    ],
                }
            ]
        };
        const options = {
            responsive: true,
            indexAxis: 'x',
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            scales: {
                y: {
                    beginAtZero: true
                }
            }

        };

        setChartData(data);
        setChartOptions(options);
        setLoading(false)
    }

    const handelSearch = () => {
        setLoading(true)
        handelChartData()

    }
    return <div style={{ direction: "rtl" }}>
        <Header></Header>
        <div className="text-muted" style={{ marginTop: "100px", textAlign: "center" }}>
            <h2>{evaluation}</h2>
        </div>
        <div className="container mt-4 ">
            <div className="row shadow-sm border p-2 rounded">
                <div className="col-sm-2">
                    <Dropdown value={evaluateKind} onChange={(e) => setEvaluateKind(e.value)}
                        options={[{ name: 'تقييم مبدأى' }, { name: 'تقييم نهائى' }]} optionLabel="name"
                        placeholder="اختر نوع التقييم" className="w-100 md:w-14rem" />
                </div>
                <div className="col-sm-3">
                    <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name"
                        placeholder="اختر فرع" className="w-100 md:w-14rem" />
                </div>
                <div className="col-sm-3">
                    <Dropdown value={jobname} onChange={(e) => setJobname(e.value)} options={jobnames} optionLabel="jobname"
                        placeholder="اختر مجموعة" className="w-100 md:w-14rem mt-sm-0 mt-2" />
                </div>
                <div className="col-sm-3">
                    <Dropdown value={sector} onChange={(e) => setSector(e.value)} options={sectors} optionLabel="sector"
                        placeholder="اختر قطاع" className="w-100 md:w-14rem mt-sm-0 mt-2" />
                </div>
                <div className="col-sm-1">
                    <Button onClick={handelSearch} className="rounded mt-sm-0 mt-2" icon="pi pi-search" loading={loading} />
                </div>
            </div>
        </div>



        <div className="row" style={{ marginTop: "20px" }}>
                <div className="container">
                <div className="container d-flex  justify-content-center">
                    {/* <div className="p-2 border rounded d-flex flex-column align-items-center">
                       <span >الاجمالى</span>  
                       <span> {statistics.a + statistics.b +statistics.c + statistics.d + statistics.e}</span>
                    </div> */}
                    <div className="p-2 border rounded mx-1 d-flex flex-column align-items-center">
                    <span style={{ fontSize:"13px"}}>ممتاز</span>   {statistics.a}
                    </div>
                    <div className="p-2 border rounded mx-1 d-flex flex-column align-items-center">
                    <span style={{ fontSize:"13px"}}>جيد جدا</span>   {statistics.b}
                    </div>
                    <div className="p-2 border rounded d-flex flex-column align-items-center">
                    <span style={{ fontSize:"13px"}}>جيد</span>   {statistics.c}
                    </div>
                    <div className="p-2 border rounded mx-1 d-flex flex-column align-items-center">
                    <span style={{ fontSize:"13px"}}>متوسط</span>   {statistics.d}
                    </div>
                    <div className="p-2 border rounded d-flex flex-column align-items-center">
                    <span style={{ fontSize:"13px"}}>ضعيف</span>   {statistics.e}
                    </div>
                </div>
                </div>
            <div className="col-sm-6 ">
                
                <div className="card m-4">
                    <Chart type="bar" data={chartData} options={chartOptions} />
                </div>
            </div>
            <div className="col-sm-6 ">
                <div className="card m-4">
                    <Chart type="pie" data={chartData} options={chartOptions} />
                </div>
            </div>
        </div>
    </div>
}