import { useEffect, useState } from 'react';
import Loading from '../../../components/Loading'
import { useOutletContext } from "react-router-dom";
import axios from 'axios';
import {  FORMSSHOW1, FORMTERMS, baseUrl } from '../../../Api/Api';
import Cookie from 'cookie-universal'
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate } from "react-router-dom";
export default function Forms() {
    const [loading, setLoading] = useState(false);

    const show = useOutletContext();
    const nav = useNavigate();
    const [forms, setForms] = useState([]);
    const [form, setForm] = useState(null)
    const [terms, setTerms] = useState([]);
    const cookie = Cookie();
    const token = cookie.get("pe");
    useEffect(() => {
        setLoading(true)
        try {
            axios.get(`${baseUrl}/${FORMSSHOW1}`,
                { headers: { Authorization: `Bearer ${token}` } }).then(data => {
                    setForms(data.data);
                    console.log(data)
                })
                .catch((err) => console.log(err))
        } catch (ex) {
            console.log(ex)
        } finally {
            setLoading(false)
        }

    }, []);

    const handelChangeForm = (e) => {
        try {
            setLoading(true)
            setForm(e.value)
            axios.get(`${baseUrl}/${FORMTERMS}/${e.value.id}`, { headers: { Authorization: `Bearer ${token}` } }).then(data => { setTerms(data.data); })
                .catch((err) => console.log(err))
        } catch (ex) {
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }
    const actionBodyTemplate = () => {
        return <>
            <Button type="button" icon="pi pi-pencil" className='rounded-circle'></Button>
            <Button type="button" icon="pi pi-times" severity='danger' className='rounded-circle mx-2'></Button>
        </>;
    };
    return (<>
        <main id="main" className={show ? "mainNarrow" : "mainWide"} style={{ direction: "rtl" }}>
            {loading && <Loading />}
            <div className="pagetitle">
                <h1>نماذج التقييم</h1>
                <div className='d-flex mt-3'>
                    <Dropdown value={form} onChange={(e) => handelChangeForm(e)} options={forms} optionLabel="name"
                        placeholder="اختر نموذج" className="w-full md:w-14rem" />
                    <Button icon="pi pi-plus-circle" outlined severity="secondary" aria-label="Bookmark" className='rounded-circle mx-2' onClick={() => nav('/dashboard/formsCreate')} />
                    <Button icon="pi pi-save" outlined severity="success" aria-label="Bookmark" className='rounded-circle mx-2 ' />
                </div>
                <div className='card mt-2 p-4' style={{ overflow:"scroll",maxHeight:"500px"}}>
                    <DataTable value={terms} size="small" showGridlines tableStyle={{ minWidth: '50rem' }}>
                        <Column field="name" header="اسم المعيار" style={{ textAlign: 'right', width: '50%' }}></Column>
                        <Column field="value" header="قيمة المعيار" style={{ textAlign: 'center', width: '10%' }}></Column>
                        <Column headerStyle={{ width: '5rem', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={actionBodyTemplate} />
                    </DataTable>
                </div>
            </div>
        </main>
    </>)
}