import { useOutletContext } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { InputText } from "primereact/inputtext";
import EmpData from "./EmpData";
export default function Employee() {
    const show = useOutletContext();
    const location = useLocation();
    const empData = location.state.employee;
    return (
        <>
            <main id="main" className={show ? "mainNarrow" : "mainWide"} style={{ direction: "rtl" }}>
                <div className="pagetitle">
                    <h1>بيانات الموظف</h1>

                </div>
                <div className="d-flex flex-column">
                    <div className="d-flex flex-sm-row flex-column my-2">
                        <EmpData empId={empData.id} title="الكود" name="code" value={empData.code} style={{maxWidth:"100px"}} disabled={true}/>
                        <EmpData empId={empData.id} title="الاسم" name="name" value={empData.name} style={{minWidth:"250px"}} disabled={false}/>
                    </div>
                    <div className="d-flex flex-sm-row flex-column my-2">
                        <EmpData empId={empData.id} title="الفرع" name="branch" value={empData.branch} style={{minWidth:"250px"}} disabled={false}/>
                        <EmpData empId={empData.id} title="الوظيفة" name="job" value={empData.job} style={{minWidth:"250px"}} disabled={false}/>
                        <EmpData empId={empData.id} title="المجموعة النوعية" name="jobname" value={empData.jobname} style={{minWidth:"250px"}} disabled={false}/>
                        <EmpData empId={empData.id} title="تاريخ التعيين" name="jobdate" value={empData.jobdate} style={{minWidth:"250px"}} disabled={false}/>
                    </div>
                    <div className="d-flex flex-sm-row flex-column my-2">
                        <EmpData empId={empData.id} title="الحالة" name="hala" value={empData.hala} style={{minWidth:"250px"}} disabled={false}/>
                        <EmpData empId={empData.id} title="المؤهل" name="qualification" value={empData.qualification} style={{minWidth:"250px"}} disabled={false}/>
                        <EmpData empId={empData.id} title="الدرجة" name="degree" value={empData.degree} style={{minWidth:"250px"}} disabled={false}/>
                        <EmpData empId={empData.id} title="تاريخها" name="degreedate" value={empData.degreedate} style={{minWidth:"250px"}} disabled={false}/>
                    </div>
                    <div className="d-flex flex-sm-row flex-column my-2">
                        <EmpData empId={empData.id} title="القطاع" name="sector" value={empData.sector} style={{minWidth:"250px"}} disabled={false}/>
                        <EmpData empId={empData.id} title="الادارة" name="sec" value={empData.sec} style={{minWidth:"250px"}} disabled={false}/>
                        <EmpData empId={empData.id} title="تقرير01" name="report01" value={empData.report01} style={{minWidth:"250px"}} disabled={false}/>
                        <EmpData empId={empData.id} title="تقرير02" name="report02" value={empData.report02} style={{minWidth:"250px"}} disabled={false}/>
                    </div>
                    <div className="d-flex flex-sm-row flex-column my-2">
                        <EmpData empId={empData.id} title="رئيس مباشر" name="evaluate0" value={empData.evaluate0} style={{minWidth:"250px"}} disabled={false}/>
                        <EmpData empId={empData.id} title="رئيس اعلى" name="evaluate1" value={empData.evaluate1} style={{minWidth:"250px"}} disabled={false}/>
                        <EmpData empId={empData.id} title=" النموذج" name="forms_id" value={empData.forms_id} style={{minWidth:"250px"}} disabled={false}/>
                    </div>
                    <div className="d-flex flex-sm-row flex-column my-2">
                        <EmpData empId={empData.id} title="اقل درجة" name="evaluate_limit" value={empData.evaluate_limit} style={{minWidth:"250px"}} disabled={false}/>
                        <EmpData empId={empData.id} title="اقل تقييم" name="evaluate_limit_txt" value={empData.evaluate_limit_txt} style={{minWidth:"250px"}} disabled={false}/>
                        
                    </div>
                </div>
            </main>
        </>
    )
}