import './report.css'
import logo from './logo.png'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react';
import axios from 'axios';
import { PRINTPREV,baseUrl } from '../../Api/Api';
import Cookie from 'cookie-universal';
export default function PrintReport () {
    const location = useLocation();
    const [data,setData] = useState([]);
    const [terms,setTerms] = useState([]);
    const [results,setResults] = useState([]);
    const [gzs,setGzs] = useState([]);
    const [agzs,setAgzs]  = useState([]);
    const [train,setTrain] = useState([]);
    const [other,setOther] = useState([]);
    const [agzb,setAgzb]  = useState([]);
    const [initEmp,setInitEmp] = useState(null);
    const [finalEmp,setFinalEmp] = useState(null);
    const cookie= Cookie();
    const token = cookie.get("pe");
    useEffect(() => {
        async function getData() {
            await axios.get(`${baseUrl}/${PRINTPREV}/${location.state.branch.name}`,
            {headers : {Authorization : `Bearer ${token}`}}).then((data) => {
                setData(data.data[0]);
                setTerms(data.data[1]);
                setResults(data.data[2]);
                setGzs(data.data[3]);
                setAgzs(data.data[4]);
                setTrain(data.data[5]);
                setOther(data.data[7]);
                setAgzb(data.data[6]);
                setInitEmp(data.data[8]);
                setFinalEmp(data.data[9]);
            })
        }
        getData()
    },[])
    return <>{data.map((item) => (
        <div className='report'> 
        <div>
            <div style={{display:"flex",flexDirection: "row",justifyContent: "space-between"}}>
                <div className='reportTitle'>
                    <p>شركه مياه الشرب والصرف الصحي بسوهاج</p>
                    <p>احدي الشركات التابعه</p>
                    <p>للشركه القابضه لمياه الشرب والصرف الصحي</p>
                </div>
                <div className='title mt-1'>
                    <p style={{fontWeight:"bold"}} className='fs-5 mb-0'>{item.formName}</p>
                    <p className='fs-6 mb-0'>{`الفترة من ${item.from} الى ${item.tw}`}</p>
                </div>
                <div style={{display:"flex",flexDirection: "column",textAlign: "center"}}>
                    <img src={logo} alt="logo" style={{width: "80px",height: "70px"}} />
                </div>
            </div>
        </div>
        <div className="body">

        <h3 style={{textDecoration: "underline"}} className='fs-4'>اولا : بيانات تملأ من واقع ملف الخدمة</h3>
        <table style={{width: "95%"}} className="table1">
            <tr>
                <td style={{width: "15%"}} className='fs-6'>الكـــــــــــــــــــــود </td>
                <td style={{width: "35%"}} className='fs-6'>{item.code}</td>
                <td style={{width: "15%"}} className='fs-6'>الاســـــــــــــــــم </td>
                <td style={{width: "35%"}} className='fs-6'>{item.name}</td>
            </tr>
            <tr>
                <td style={{width: "15%"}} className='fs-6'>الحالـة الوظيفيــــة </td>
                <td style={{width: "35%"}} className='fs-6'>{item.hala}</td>
                <td style={{width: "15%"}} className='fs-6'>المؤهــــــــــــــــل </td>
                <td style={{width: "35%"}} className='fs-6'>{item.qualification}</td>
            </tr>
            <tr>
                <td style={{width: "15%"}} className='fs-6'>الدرجـــــــــــــة </td>
                <td style={{width: "35%"}} className='fs-6'>{item.emp_degree}</td>
                <td style={{width: "15%"}} className='fs-6'>تاريــــخ شغلهــــا </td>
                <td style={{width: "35%"}} className='fs-6'>{item.degreedate}</td>
            </tr>
            <tr>
                <td style={{width: "15%"}} className='fs-6'>المجموعة </td>
                <td style={{width: "35%"}} className='fs-6'>{item.jobname}</td>
                <td style={{width: "15%"}} className='fs-6'>العمـل القائــم بـــه </td>
                <td style={{width: "35%"}} className='fs-6'>{item.job}</td>
            </tr>
            <tr>
                <td style={{width: "15%"}} className='fs-6'>تاريخ التعييــــن </td>
                <td style={{width: "35%"}} className='fs-6'>{item.jobdate}</td>
                <td style={{width: "15%"}} className='fs-6'>القطـــــــــــــــــــاع </td>
                <td style={{width: "35%"}} className='fs-6'>{item.sector}</td>
            </tr>
            <tr>
                <td style={{width: "15%"}} className='fs-6'>الفـــــــــــــــرع </td>
                <td style={{width: "35%"}} className='fs-6'>{item.branch}</td>
                <td style={{width: "15%"}} className='fs-6'>جهة العمـــــــــــل </td>
                <td style={{width: "35%"}} className='fs-6'>{item.sec}</td>
            </tr>
            <tr>
                <td style={{width: "15%"}} className='fs-6'>تقرير 2021 </td>
                <td style={{width: "35%"}} className='fs-6'>{item.report01}</td>
                <td style={{width: "15%"}} className='fs-6'> تقرير 2022 </td>
                <td style={{width: "35%"}} className='fs-6'>{item.report02}</td>
            </tr>
        </table>
        <div style={{display: "flex",flexDirection: "row"}}>
            <div style={{width: "50%"}}>
                <h3 style={{textDecoration: "underline"}} className='fs-3'>ثانياً : عناصر التقييم</h3>
                <table style={{width: "95%"}} className="table2">
                    
                    <tr>
                        <th style={{width: "10%"}}>م </th>
                        <th style={{width: "60%"}}>المعيار</th>
                        <th style={{width: "10%"}}>الدرجة </th>
                        <th style={{width: "10%"}}>الرئيس المباشر</th>
                        <th style={{width: "10%"}}>الرئيس الاعلى</th>
                    </tr>
                    {terms.filter((itm) => itm.forms_id === item.forms_id).map((im,index) => 
                        <tr style={{fontSize: "10pt !important",textAlign: "right"}}>
                            <td style={{width: "10%"}}>{index+1}</td>
                            <td style={{width: "40%"}}>{im.name}</td>
                            <td style={{width: "10%"}}>{im.value}</td>
                            {results.filter((res) => res.employee_id === item.id && res.form_terms_id === im.id && res.kind === 'i').map((result) => (
                                <td>{result.result}</td> 
                            ))}
                            {results.filter((res) => res.employee_id === item.id && res.form_terms_id === im.id && res.kind === 'f').map((result) => (
                                <td>{result.result}</td> 
                            ))}
                            {/* <td>{results.filter((res) => res.employee_id === item.id && res.form_terms_id === im.id && res.kind === 'i')[0].result}</td> */}
                            {/* <td>{results.filter((res) => res.employee_id === item.id && res.form_terms_id === im.id && res.kind === 'f')[0].result}</td> */}
                        </tr>
                    )}
                    
                   
                    <tr>
                        <td colspan="2">الاجمالى</td>
                        <td style={{width: "10%"}}>100</td>
                        <td><span>{item.degree}</span></td>
                        <td><span>{item.degree_final}</span></td>
                    </tr>
                </table>
            </div>
            <div style={{width: "50%"}}>
                <h3 style={{textDecoration: "underline"}} className='fs-3'>مجموع الدرجات والتقييم : </h3>
                <table style={{width: "95%"}} className="table2">
                    <tr>
                        <th style={{width: "20%"}}>ممتاز</th>
                        <th style={{width: "20%"}}>جيد جدا</th>
                        <th style={{width: "20%"}}>جيد </th>
                        <th style={{width: "20%"}}>متوسط</th>
                        <th style={{width: "20%"}}>ضعيف</th>
                    </tr>
                    <tr style={{fontSize: "10pt !important",textAlign: "right"}}>
                        <td style={{width: "20%"}}>من 90 الى
                            اقل من 100
                        </td>
                        <td style={{width: "20%"}}>من 80 الى اقل من 90</td>
                        <td style={{width: "20%"}}>من 65 الى اقل من 80</td>
                        <td style={{width: "20%"}}>من 50 الى
                            اقل من 65
                        </td>
                        <td style={{width: "20%"}}>اقل من 50</td>
                    </tr>
                </table>
                <h3 style={{textDecoration: "underline"}} className='fs-4'>الجزاءات الحاصل عليها : </h3>
                <div style={{fontSize:"12px"}}>
                    {gzs.filter(gf => gf.employees_id === item.id ).length > 0 ? gzs.filter(gf => gf.employees_id === item.id ).map((g => (
                        <>{g.desc} - </>
                    ))) : <>لا يوجد .</>}
                </div>
                

                <h3 style={{textDecoration: "underline"}} className='fs-4'>الدورات التدريبية الحاصل عليها :</h3>
                <div style={{fontSize:"12px"}}>
                    {train.filter(gf => gf.employees_id === item.id ).length >0 ? train.filter(gf => gf.employees_id === item.id ).map((g => (
                        <>{g.desc} - </>
                    ))) : <>لا يوجد .</>}
                </div>
                <h3 style={{textDecoration: "underline"}} className='fs-4'>مواطن القوى او الضعف :</h3>
                <div style={{fontSize:"12px"}}>
                    {
                        item.excel_weak + ' - ' + item.excel_weak_final
                    }
                </div>
                <h3 style={{textDecoration: "underline"}} className='fs-4'>خطابات شكر - علاوات تشجيعية - الخ:</h3>
                <div style={{fontSize:"12px"}}>
                    {other.filter(gf => gf.employees_id === item.id ).length >0 ? other.filter(gf => gf.employees_id === item.id ).map((g => (
                        <>{g.desc} - </>
                    ))) : <>لا يوجد .</>}
                </div>
                <h3 style={{textDecoration: "underline"}} className='fs-4'>عدد ايام الغياب بدون إذن:</h3>
                <div style={{fontSize:"12px"}}>
                    {agzb.filter(gf => gf.employees_id === item.id ).length >0 ? agzb.filter(gf => gf.employees_id === item.id ).map((g => (
                        <>{g.desc} - </>
                    ))) : <>لا يوجد .</>}
                </div>
            </div>
        </div>
    </div>
    <div className="ftr">
        <table style={{width: "95%", textAlign: "center",marginTop: "5px"}} className="table3">
            <tr>
                <th style={{width: "25%"}}> التقييم المبدئى <span className='fs-6'>{item.evaluate}</span></th>
                <th style={{width: "25%"}}>التقييم النهائى <span className='fs-6'>{item.evaluate_final}</span></th>
                <th style={{width: "25%"}}>مرتبة التقييم </th>
                <th style={{width: "25%"}}></th>
            </tr>
            <tr>
                <td>الاسم / {initEmp.filter(ie => ie.code === item.evaluate0 )[0].name}</td>
                <td>الاسم / {finalEmp.filter(ie => ie.code === item.evaluate1 )[0].name}</td>
                <td><span style={{fontSize:"14px"}}>{item.evaluate_final}</span> - <span style={{fontSize:"14px"}}>{item.degree_final}</span></td>
            </tr>
            <tr>
                <td>الوظيفة / {initEmp.filter(ie => ie.code === item.evaluate0)[0].job}</td>
                <td>الوظيفة / {finalEmp.filter(ie => ie.code === item.evaluate1)[0].job}</td>
                <td>اعتماد لجنة شئون العاملين </td>
                <td>يعتمد رئيس مجلس الادارة</td>
            </tr>
            <tr>
                <td>التوقيع / </td>
                <td>التوقيع / </td>
                <td>

                    <div style={{display:'flex',border : '1pt solid gray',height: '60px',textAlign: 'right'}}>
                        <div style={{width: "75%"}} className='p-1'>
                            <p  className='pagge m-0'>وافقت لجنة شئون العاملين بشركة مياه الشرب والصرف الصحى بسوهاج</p>
                            <p className='pagge m-0'>بجلستها رقم (&#160;&#160;&#160;) بتاريخ  &#160;&#160;&#160;/ &#160;&#160; &#160;/      </p>
                            <p className='pagge m-0'>على منح تقرير بمجموع ( <span >{item.degree_final}</span> ) بتقدير <span>{item.evaluate_final}</span></p>
                            <p className='pagge m-0'>التوقيع  /</p>
                        </div>
                        <div style={{width: "25%",borderRight: "1px solid gray"}}>
                            <p className='pagge0'><span style={{fontSize:"14px",fontWeight:"bold"}}>{item.evaluate_final}</span></p>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
    </div>
    ))}</>
}