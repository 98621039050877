import { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import './style.css'
import {Outlet} from "react-router-dom";
export default function Dashboard() {
    const [show,setShow] = useState(true);
    const handleShow = () => {
    
        setShow(!show);
    }
    return (
        <>
            <Header show={handleShow}/>

            <div>
            <Sidebar show={show}/>
            <Outlet  context={show}/>
            </div>

        </>
    )
}