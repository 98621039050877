import Carousl from "./Carousl";
import Header from "./Header";
import Hero from "./Hero";
import "./style.css"

export default function HomePage() {
    
    return (
        <div style={{direction:"rtl"}}>
            <Header />
            <Hero />
            <Carousl />
        </div>
    )
}