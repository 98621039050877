import { Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './pages/frontend/HomePage'
import Login from './pages/Auth/Login'
import Register from './pages/Auth/Register'
import Dashboard from './pages/backend/Dashboard'
import RequireAuth from './pages/Auth/RequireAuth';
import Evaluation from './pages/evaluation/Evaluation';
import Print from './pages/print/Print';
import Error403 from './components/Error403';
import RequireBack from './pages/Auth/RequireBack';
import Users from './pages/backend/users/Users';
import Evaluate01 from './pages/evaluation/Evaluate01';
import Evaluate02 from './pages/evaluation/Evaluate02';
import Evaluations from './pages/backend/evaluations/Evaluations';
import Forms from './pages/backend/forms/Forms';
import Create from './pages/backend/forms/Create';
import PrintReport from './pages/print/PrintReport';
import Statistics from './pages/statistics/Statistics';
import ChangePassword from './pages/Auth/ChangePassword';
import Employees from './pages/backend/employees/Employees';
import Employee from './pages/backend/employees/Employee';
import EmployeesF from './pages/employees/EmployeesF';
import Review from './pages/statistics/Review';
import EvaluationsQuery from "./pages/evaluations/EvaluationsQuery";

function App() {
  return (
    // 1995 admin - 1996 moderator - 1997 evaluator - 1998 user
    <div className="App">
      <Routes>
        <Route path='/' element={<HomePage />}></Route>
        
        <Route element={<RequireBack />}>
          <Route path='/login' element={<Login />}></Route>
        </Route>

        <Route element={<RequireAuth allowedRole={['1995']} />}>
          <Route path='/register' element={<Register />}></Route>
          <Route path='/dashboard' element={<Dashboard />}>
            <Route path='users' element={<Users />}></Route>
            <Route path='employee' element={<Employee />}></Route>
            <Route path='employees' element={<Employees />}></Route>
            <Route path='evaluations' element={<Evaluations />}></Route>
            <Route path='forms' element={<Forms />}></Route>
            <Route path='formsCreate' element={<Create />}></Route>
          </Route>
        </Route>
        <Route element={<RequireAuth allowedRole={['1995', '1996']} />}>
          <Route path='/statistics' element={<Statistics />}></Route>
          <Route path='/review' element={<Review />}></Route>
          <Route path='/employeesF' element={<EmployeesF />}></Route>
          <Route path={"/evaluationsQuery"} element={<EvaluationsQuery />}></Route>
        </Route>
        <Route element={<RequireAuth allowedRole={['1995', '1996', '1997']} />}>
          <Route path='/evaluation' element={<Evaluation />}></Route>
          <Route path='/evaluate01' element={<Evaluate01 />}></Route>
          <Route path='/evaluate02' element={<Evaluate02 />}></Route>
        </Route>
        <Route element={<RequireAuth allowedRole={['1995', '1996', '1998']} />}>
          <Route path='/print' element={<Print />}></Route>

          <Route path='/printReport' element={<PrintReport />}></Route>
        </Route>
        <Route element={<RequireAuth allowedRole={['1995', '1996', '1997', '1998']} />}>
        <Route path='/changePassword' element={<ChangePassword />}></Route>
        </Route>
        <Route path='/403' element={<Error403 />}></Route>
      </Routes>

    </div>
  );
}

export default App;
