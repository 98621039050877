import Header from '../frontend/Header'
import { Card } from 'primereact/card';
import { useEffect, useState } from 'react';
import Cookie from 'cookie-universal'
import axios from 'axios';
import { EVALUATION, EVALUATION01, EVALUATION02, baseUrl } from '../../Api/Api';
import Loading from '../../components/Loading';
import { useNavigate } from "react-router-dom";
import { Chip } from 'primereact/chip';
import { Knob } from 'primereact/knob';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
export default function Evaluation() {
    const [value, setValue] = useState(40);
    const navigation = useNavigate();
    const [emps, setEmps] = useState([]);
    const [empsBasic, setEmpsBasic] = useState([]);
    const [evluation, setEvaluation] = useState({});
    const [current, setCurrent] = useState('');
    const [loading, setLoading] = useState(false);
    const [kind, setKind] = useState(1)
    const cookie = Cookie();
    const token = cookie.get("pe");

    useEffect(() => {
        let evalu = {};
        async function getEvaluation() {
            setLoading(true)
            await axios.get(`${baseUrl}/${EVALUATION}`, { headers: { Authorization: `Bearer ${token}` } })
                .then((data) => {
                    setEvaluation(data.data);
                    evalu = (data.data);

                }).catch((err) => console.log(err))
            getData()
        }

        async function getData() {

            if (Object.keys(evalu).length > 0) {
                //************ */
                // تقييم مبدئى
                //************* */
                let currentDate = new Date();
                let initial_from = new Date(evalu.initial_from);
                let intitial_to = new Date(evalu.intial_to);

                
                if (currentDate >= initial_from && currentDate <= intitial_to) {
                    setKind(1)
                    setCurrent(`تقييم الاداء المبدأي من ${initial_from.toLocaleDateString('en-GB')} الى ${intitial_to.toLocaleDateString('en-GB')}`)
                    await axios.get(`${baseUrl}/${EVALUATION01}`, { headers: { Authorization: `Bearer ${token}` } })
                        .then((data) => {
                            setEmpsBasic(data.data);
                            setEmps(data.data)
                            setValue(data.data.filter((item) => item.evaluate !== null).length)
                        }).catch(() => console.log("error in get data"))
                } else {

                    //************ */
                    // تقييم نهائى
                    //************* */

                    let final_from = new Date(evalu.final_from);
                    let final_to = new Date(evalu.final_to);
                    if (currentDate >= final_from && currentDate <= final_to) {

                        setCurrent(`تقييم الاداء النهائي من ${final_from.toLocaleDateString('en-GB')} الى ${final_to.toLocaleDateString('en-GB')}`)
                        setKind(2)
                        await axios.get(`${baseUrl}/${EVALUATION02}`, { headers: { Authorization: `Bearer ${token}` } })
                            .then((data) => { setEmps(data.data); setValue(data.data.filter((item) => item.evaluate_final !== null).length) }).catch(() => console.log("error in get data"))
                    }
                }
            }
        }

        getEvaluation()
        // getData()
        setLoading(false)
    }, [])
    const openReport = (id, emp, edit) => {
        if (kind === 1) {
            navigation("/evaluate01", { state: { id: id, emp: emp, edit: edit } })
        } else {
            navigation("/evaluate02", { state: { id: id, emp: emp, edit: edit } })
        }

    }
    const search = (e) => {
        let val = e.target.value;
        if (val === '') {
            setEmps(empsBasic)
        } else {
            let empSearch = [];
            empSearch = empsBasic.filter((item) => item.name.includes(val))
            setEmps(empSearch)
        }
    }

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };

    const bodyTemplate = (item) => {
        return (
            kind === 1 ?
                item.evaluate === null ?
                    <button type="button" className="btn btn-outline-primary btn-sm"
                        style={{ minWidth: "100px" }} onClick={() => openReport(item.forms_id, item, false)}>تقييم</button>
                    :
                    <div className='d-flex flex-row justify-content-between'>
                        <Chip style={{ direction: "ltr" }} label={item.evaluate + '-' + item.degree} icon="pi pi-thumbs-up-fill" />
                        <button type="button" className="btn btn-outline-success btn-sm"
                            style={{ minWidth: "50px" }} onClick={() => openReport(item.forms_id, item, true)} title='تعديل التقييم'>
                            <span className='pi pi-pencil' style={{ fontSize: "12px" }}></span>
                        </button>
                    </div>
                : item.evaluate_final === null ?
                    <div className='d-flex flex-row justify-content-between'>
                        <Chip style={{ direction: "ltr", marginLeft: "2px" }} label={
                            item.evaluate !== null ?
                                'تقرير مبدأي ' + '||' + item.evaluate + '-' + item.degree
                                :
                                <>
                                    تقرير مبدأي لم يتم  <span className="pi pi-times"></span>
                                </>
                        } />
                        <button disabled={item.evaluate === null ? "disabled" : ""} type="button"
                            className={item.evaluate === null ? "btn btn-outline-danger btn-sm" : "btn btn-outline-primary btn-sm"}
                            style={{ minWidth: "100px" }} onClick={() => openReport(item.forms_id, item, false)}>تقييم</button>

                    </div>
                    :
                    <>
                        <div className='d-flex flex-row justify-content-between'>
                            <Chip style={{ direction: "ltr", marginLeft: "2px" }} label={'تقرير مبدأي ' + '||' + item.evaluate + '-' + item.degree} />
                            <Chip style={{ direction: "ltr" }} label={'تقرير نهائى ' + '||' + item.evaluate_final + '-' + item.degree_final} icon="pi pi-thumbs-up-fill" />
                            <button type="button" className="btn btn-outline-success btn-sm"
                                style={{ minWidth: "50px" }} onClick={() => openReport(item.forms_id, item, true)} title='تعديل التقييم'>
                                <span className='pi pi-pencil' style={{ fontSize: "12px" }}></span>
                            </button>
                        </div>
                    </>
        );
    };

    const header = renderHeader();
    return (
        <>
            {loading && <Loading />}
            <div style={{ direction: "rtl" }}>
                <Header />
                <Card title={
                    <div className='d-flex flex-sm-row flex-column'>
                        {evluation.name}
                        <Chip style={{ fontSize: "12px", maxWidth: "300px", maxHeight: "30px", marginRight: "10px" }} label={current} />
                        <div className='col-sm-4 d-flex  justify-content-center'>
                            <span style={{ marginTop: "80px" }}>اجمالى {emps.length}</span>
                            <Knob value={value} min={0} max={emps.length} readOnly />
                        </div>
                    </div>
                } style={{ margin: "80px auto 10px auto", maxWidth: "90%" }}>
                    <DataTable globalFilterFields={['code', 'name', 'sec', 'job']} header={header}
                        filters={filters} filterDisplay="row" size='small' value={emps} showGridlines scrollable scrollHeight="400px" tableStyle={{ minWidth: "800px" }}>
                        <Column field="code" header="الكود" style={{ textAlign: "right" }}></Column>
                        <Column field="name" header="الاسم" style={{ textAlign: "right" }}></Column>
                        <Column field="job" header="الوظيفة" style={{ textAlign: "right" }}></Column>
                        <Column field="sec" header="المحطة / الادارة" style={{ textAlign: "right" }}></Column>
                        <Column field="conditioin" header="الحالة" style={{ textAlign: "right" }} body={bodyTemplate}></Column>
                    </DataTable>

                </Card>
            </div>
        </>

    )
}