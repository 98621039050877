import { useEffect, useState } from "react";
import Header from "../frontend/Header";
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import Cookie from 'cookie-universal'
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import axios from "axios";
import { baseUrl } from "../../Api/Api";
export default function EmployeesF() {
    const cookie = Cookie();
    const token = cookie.get("pe");
    const [empCode, setEmpCode] = useState('')
    const [evaluation, setEvalution] = useState(null);
    const [terms, setTerms] = useState(null);
    const [expandedRows, setExpandedRows] = useState(null);
    const getData = async () => {
        try {
            await axios.get(`${baseUrl}/evaluations/${empCode}`
                , { headers: { Authorization: `Bearer ${token}` } }
            ).then((data) => {
                console.log(data.data)
                setEvalution(data.data[0])
                setTerms(data.data[1])
            })
        } catch (err) {
            console.log(err)
        }
    }
    const allowExpansion = (rowData) => {
        return rowData.name.length > 0;
    };
    const rowExpansionTemplate = (data) => {
        return (
            <div className="p-3">
                <table className="table table-bordered">
                    <tbody>
                        {

                            terms.filter((item) => item.evaluation_id === data.id).map((itm, index) => {
                                return (
                                    <tr key={index} style={{ textAlign: "right" }}>
                                        <td style={{ width: "50%" }}>{itm.name}</td>
                                        <td style={{ width: "10%" }}>{itm.value}</td>
                                        <td style={{ width: "10%" }}>{itm.result}</td>
                                        <td>{
                                            (itm.result / itm.value) >= .9 ?
                                                <div className="progress" role="progressbar" aria-label="Success example"
                                                    aria-valuenow={(itm.result / itm.value) * 100} aria-valuemin="0" aria-valuemax="100">
                                                    <div className="progress-bar bg-success" style={{ width: (itm.result / itm.value) * 100 + "%" }}>{itm.result / itm.value * 100}</div>
                                                </div>
                                                :
                                                (itm.result / itm.value) >= .80 ?
                                                    <div className="progress" role="progressbar" aria-label="Info  example"
                                                        aria-valuenow={(itm.result / itm.value) * 100} aria-valuemin="0" aria-valuemax="100">
                                                        <div className="progress-bar bg-info" style={{ width: (itm.result / itm.value) * 100 + "%" }}>{itm.result / itm.value * 100}</div>
                                                    </div>
                                                    :
                                                    (itm.result / itm.value) >= .65 ?
                                                        <div className="progress" role="progressbar" aria-label="Warning  example"
                                                            aria-valuenow={(itm.result / itm.value) * 100} aria-valuemin="0" aria-valuemax="100">
                                                            <div className="progress-bar bg-warning" style={{ width: (itm.result / itm.value) * 100 + "%" }}>{itm.result / itm.value * 100}</div>
                                                        </div>
                                                        :
                                                        <div className="progress" role="progressbar" aria-label="danger  example"
                                                            aria-valuenow={(itm.result / itm.value) * 100} aria-valuemin="0" aria-valuemax="100">
                                                            <div className="progress-bar bg-danger" style={{ width: (itm.result / itm.value) * 100 + "%" }}>{itm.result / itm.value * 100}</div>
                                                        </div>
                                        }</td>
                                    </tr>
                                )
                            })

                        }
                    </tbody>
                </table>
            </div>
        );
    };
    return (
        <div style={{ direction: "rtl" }}>
            <Header></Header>
            <div className="text-muted" style={{ marginTop: "100px", textAlign: "center" }}>
                <h2>الاستعلام عن تقييم اداء العاملين</h2>
            </div>
            <div className="card p-3 m-1 m-sm-5">
                <div className="mx-w-50">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText placeholder="الكود" value={empCode} onChange={(e) => setEmpCode(e.target.value)} />
                    </span>
                    <Button icon="pi pi-search" className="rounded mx-2" onClick={getData} />
                </div>
                <DataTable value={evaluation} tableStyle={{ minWidth: '50rem' }} showGridlines
                    expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={rowExpansionTemplate}

                >
                    <Column expander={allowExpansion} style={{ width: '5rem' }} />
                    <Column field="name" header="التقييم"></Column>
                    <Column field="from" header="من"></Column>
                    <Column field="tw" header="الى"></Column>
                    <Column field="evaluate" header="تقييم مبدئى"></Column>
                    <Column field="degree" header="تقدير مبدئى"></Column>
                    <Column field="evaluate_final" header="تقييم نهائى"></Column>
                    <Column field="degree_final" header="تقدير نهائى"></Column>
                </DataTable>
            </div>
        </div>
    )
}