import { useEffect, useState } from "react";
import Header from "../frontend/Header";
import axios from "axios";
import Cookie from 'cookie-universal'
import { EVALUATION, baseUrl } from "../../Api/Api";
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
export default function Review() {
    const [evaluateKind, setEvaluateKind] = useState({ name: "تقييم نهائى" });
    const [users, setUsers] = useState(null)
    const [employeesCount,setEmployeesCount] = useState(null)
    const [employeesCountDone,setEmployeesCountDonet] = useState(null)
    const [evaluation, setEvalution] = useState("");
    const cookie = Cookie();
    const token = cookie.get("pe");
    useEffect(() => {
        async function getEvaluation() {
            await axios.get(`${baseUrl}/${EVALUATION}`, { headers: { Authorization: `Bearer ${token}` } }).then((data) => {
                setEvalution(`${data.data.name}`)
            });
        }
        getEvaluation()
    }, []);
    const getEval = async () => {
        if (evaluateKind.name === "تقييم مبدأى") {
            await axios.get(`${baseUrl}/reviewI`, { headers: { Authorization: `Bearer ${token}` } }).then((data) => {
                console.log(data.data.employeesCountDone)
                setUsers(data.data.users)
                setEmployeesCount(data.data.employeesCount)
                setEmployeesCountDonet(data.data.employeesCountDone)
            });
        } else {

        }
    }
    return (
        <>
            <div style={{ direction: "rtl" }}>
                <Header></Header>
                <div className="text-muted" style={{ marginTop: "100px", textAlign: "center" }}>
                    <h2>{evaluation}</h2>
                </div>
                <div className="container mt-4 ">
                    <div className="row shadow-sm border p-2 rounded">
                        <div className="col-sm-2">
                            <Dropdown value={evaluateKind} onChange={(e) => setEvaluateKind(e.value)}
                                options={[{ name: 'تقييم مبدأى' }, { name: 'تقييم نهائى' }]} optionLabel="name"
                                placeholder="اختر نوع التقييم" className="w-100 md:w-14rem" />
                        </div>
                        <div className="col-sm-1">
                            <Button onClick={getEval} className="rounded mt-sm-0 mt-2" icon="pi pi-search" />
                        </div>
                    </div>
                </div>
                <div>
                    <table className="table">
                        <thead>
                        <tr>

                            <td>الكود</td>
                            <td>الاسم</td>
                            <td>الوظيفة</td>
                            <td>جهة العمل</td>
                            <td>العدد</td>
                            <td>تم تقييمهم</td>
                            </tr>
                        </thead>
                        <tbody>
                            {users != null && users.map((item) => (
                                <tr>
                                    <td>{item.code}</td>
                                    <td>{item.name}</td>
                                    <td>{item.job}</td>
                                    <td>{item.sec}</td>
                                    <td>{employeesCount.filter((itm) => itm.code == item.code )[0].countEvaluatesI}</td>
                                    <td>{employeesCountDone.filter((itm) => itm.evaluate0 == item.code ).length}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>
        </>
    )
}