import { useOutletContext } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from "primereact/card";
import axios from "axios";
import { USERS, baseUrl } from "../../../Api/Api";
import Cookie from 'cookie-universal'
import { Chip } from "primereact/chip";
import Loading from "../../../components/Loading";
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { Button } from 'primereact/button';
import { useNavigate } from "react-router-dom";
export default function Users() {
    const navigate = useNavigate();
    const cookie = Cookie();
    const token = cookie.get("pe")
    const show = useOutletContext();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        name: { value: null, matchMode: FilterMatchMode.CONTAINS,
            value: null, matchMode: FilterMatchMode.STARTS_WITH },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const renderHeader = () => {
        return (
            <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <Button icon="pi pi-plus" raised className="rounded" onClick={() => navigate("/register")}/>
        </div>
        );
    };
    useEffect(() => {
        async function getUsers () {
            
            try {
                setLoading(true)
                await axios.get(`${baseUrl}/${USERS}`,{headers : {Authorization : `Bearer ${token}`}})
            .then((data) => setUsers(data.data)).catch(() => console.log("error on get data"))
            }catch(err) {

            }finally{
                setLoading(false)
            }
        }
        
        getUsers()
       
    }, [])
    const roleTemplate = (rowData) => {
        if ( rowData.role === '1995') {
            return <Chip label="ادمين"/>
        }
        if ( rowData.role === '1996') {
            return <Chip label="ادارة"/>
        }
        if ( rowData.role === '1997') {
            return <Chip label="مقيم"/>
        }
        if ( rowData.role === '1998') {
            return <Chip label="مستخدم"/>
        }

    };
    const header = renderHeader();
    return (<>
    
    {loading && <Loading />}
        <main id="main" className={show ? "mainNarrow" : "mainWide"} style={{ direction: "rtl" }}>
        <div className="pagetitle">
            <h1>المستخدمين</h1>

        </div>
        <Card>
            <DataTable globalFilterFields={['name','code']} header={header} filterDisplay="row" value={users} tableStyle={{ minWidth: '50rem'}} size="small" showGridlines paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]}>
                <Column field="code" filter  header="الكود" align={"center"}></Column>
                <Column field="name" filter header="اسم المستخدم" align={"center"}></Column>
                <Column field="email" filter header="الايميل" align={"center"}></Column>
                <Column body={roleTemplate} header="الصلاحية" align={"center"}></Column>
            </DataTable>
        </Card>
    </main></>)
}