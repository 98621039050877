export const baseUrl = `https://www.petest.scwwsports.com/api`
export const LOGIN = `login`
export const LOGOUT = `logout`
export const CHANGEPASSWORD = `changePassword`
export const USER = `user`

export const USERS = 'users'
export const REGISTER = 'register'

export const EVALUATION01 = 'evaluate01'
export const EVALUATION02 = 'evaluate02'
export const REPORT01 = 'report01'
export const REPORT02 = 'report02'
export const EVALUATION = 'evaluation'

export const SAVEREPORT01 = 'saveReport01'
export const SAVEREPORT02 = 'saveReport02'

export const EVALUATIONCREATE = 'evaluationCreate';
export const EVALUATIONUPDATE = 'evaluationUpdate';

export const FORMSSHOW = 'formsShow';
export const FORMSSHOW1 = 'formsShow1';
export const FORMCREATE = 'formCreate';
export const FORMUPDATE = 'formUpdate';
export const FORMDELETE = 'formDelete';
export const FORMTERMS = 'formTerms';

export const PRINTPREV = 'printPrev';


export const EMPLOYEES = 'employees'