import { faChevronDown, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate} from "react-router-dom";
import 'primeicons/primeicons.css';
export default function Sidebar(props) {
  const nav = useNavigate();
  
    return (<>
  <aside id="sidebar" className={props.show ? 'sidebar showSidebar' : 'sidebar hideSidebar'} >

    <ul className="sidebar-nav" id="sidebar-nav">

      <li className="nav-item" onClick={() => nav('/dashboard')} style={{cursor:"pointer"}}>
        <a className="nav-link " >
        <span className="pi pi-microsoft mx-2"></span>
          <span>Dashboard</span>
        </a>
      </li>

      <li className="nav-item" onClick={() => nav('/dashboard/users')} style={{cursor:"pointer"}}>
        <a className="nav-link" >
        <span className="pi pi-users mx-2"></span><span>المستخدمين</span>
        </a>
        
      </li>
      <li className="nav-item" onClick={() => nav('/dashboard/employees')} style={{cursor:"pointer"}}>
        <a className="nav-link" >
        <span className="pi pi-user mx-2"></span><span>الموظفين</span>
        {/* <FontAwesomeIcon icon={faChevronDown} /> */}
        </a>
        
      </li>
      <li className="nav-item" onClick={() => nav('/dashboard/evaluations')} style={{cursor:"pointer"}}>
        <a className="nav-link" >
        <span className="pi pi-book mx-2"></span><span>التقييم</span>
        {/* <FontAwesomeIcon icon={faChevronDown} /> */}
        </a>
        
      </li>
      <li className="nav-item" onClick={() => nav('/dashboard/forms')} style={{cursor:"pointer"}}>
        <a className="nav-link" >
        <span className="pi pi-table mx-2"></span><span>نماذج التقييم</span>
        {/* <FontAwesomeIcon icon={faChevronDown} /> */}
        </a>
        
      </li>
      <li className="nav-item" onClick={() => nav('/dashboard/users')} style={{cursor:"pointer"}}>
        <a className="nav-link" >
        <span className="pi pi-list mx-2"></span><span>عناصر التقييم</span>
        {/* <FontAwesomeIcon icon={faChevronDown} /> */}
        </a>
        
      </li>

    </ul>

  </aside>
    </>)
}