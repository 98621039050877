import axios from "axios";
import { useState } from "react";
import { Card } from "react-bootstrap";
import { REGISTER, baseUrl } from "../../Api/Api";
import "./style.css"
import { Oval } from 'react-loader-spinner'
import { useNavigate } from "react-router-dom";
export default function Register() {
    const navigate = useNavigate();
    const [form, setForm] = useState({
        code:"",
        name: "",
        email: "",
        password: "",
        role :""
    })

    const [error, setError] = useState("");

    const [loading, setLoading] = useState(false);
    const handleFormChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }
    const handleClear = () => {
        setForm({
            code:"",
            name: "",
            email: "",
            password: "",
            role :""
        })
    }
    // const savePassResetAll = async () => {
    //     setLoading(true)
    //     try {
    //         await axios.post(`${baseUrl}/savePassResetAll`).then(() => alert('تم بنجاح'))
    //     }catch(err) {
    //         alert(err)
    //     }finally {
    //         setLoading(false)
    //     }
    // }
    const savePassReset = async () => {
        setLoading(true)
        try {
            await axios.post(`${baseUrl}/savePassReset/${form.code}`).then(() => alert('تم بنجاح'))
        }catch(err) {
            alert(err)
        }finally {
            setLoading(false)
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
       
        try {
            setLoading(true)
            await axios.post(`${baseUrl}/${REGISTER}`, form).then(() =>  navigate("/dashboard/users"))
            handleClear()
        } catch (err) {
        
            setError("خطا اثناء تسجيل المستخدم")
        } finally {
            setLoading(false)
        }
    }
    return (
        <>
            <Oval
                height={80}
                width={80}
                color="#4fa94d"
                wrapperStyle={{}}
                wrapperClass="spinner_container"
                visible={loading}
                ariaLabel='oval-loading'
                secondaryColor="#4fa94d"
                strokeWidth={2}
                strokeWidthSecondary={2}

            />
            <div style={{ maxWidth: "500px", margin: "100px auto", direction: "rtl", padding: "10px" }}>
                <Card className="shadow">
                    <form onSubmit={handleSubmit} style={{ padding: "10px" }}>
                        <h3 className="text-center pb-5 pt-3 fw-bold">تسجيل مستخدم</h3>
                        <div class="form-outline mb-4">
                            <label class="form-label" htmlFor="form2Example4">الكود الوظيفي</label>
                            <input type="text" id="form2Example4" name="code" class="form-control" value={form.code} onChange={handleFormChange} required />

                        </div>
                        <div class="form-outline mb-4">
                            <label class="form-label" htmlFor="form2Example3">اسم المستخدم</label>
                            <input type="text" id="form2Example3" name="name" class="form-control" value={form.name} onChange={handleFormChange} required />

                        </div>

                        <div class="form-outline mb-4">
                            <label class="form-label" htmlFor="form2Example1">الايميل</label>
                            <input type="email" id="form2Example1" name="email" class="form-control" value={form.email} onChange={handleFormChange} required />

                        </div>
                        <div class="form-outline mb-4">
                            <label class="form-label" htmlFor="form2Example1">الصلاحية</label>
                            <select class="form-select" name="role" aria-label="Default select example"  onChange={handleFormChange}>
                                <option selected>الصلاحية</option>
                                <option value="1995">ادمين</option>
                                <option value="1996">ادارة</option>
                                <option value="1997">مقيم</option>
                                <option value="1998">مستخدم</option>
                            </select>
                            </div>
                        <div class="form-outline mb-4">
                            <label class="form-label" htmlFor="form2Example2">كلمة المرور</label>
                            <input type="password" id="form2Example2" name="password" class="form-control" value={form.password} onChange={handleFormChange} minLength="6"  required/>

                        </div>



                        <button type="submit" class="btn btn-primary btn-block mb-2 w-100">اضافة المستخدم</button>
                    </form>

                    <button onClick={savePassReset} class="btn btn-primary btn-block mb-2 w-100">تصفير الباسوورد</button>
                    {error !== "" && <div class="alert alert-danger mx-2" role="alert">
                        {error}
                    </div>}
                </Card>

            </div>
        </>
    )
}