import axios from "axios";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { LOGIN, baseUrl } from "../../Api/Api";
import { useNavigate } from "react-router-dom";
import {Navigate} from "react-router-dom";
import "./style.css"
import Cookie from 'cookie-universal'
import Loading from '../../components/Loading'
export default function Login() {
 const cookie = Cookie();
  const [form, setForm] = useState({
    email: "",
    password: ""
  })
  useEffect(() => {
    const token = cookie.get("pe");
    console.log(token)
    if ( token !== undefined ) {
      <Navigate to='/home' />
    }
  },[])
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleFormChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })

  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      setLoading(true)
      const res = await axios.post(`${baseUrl}/${LOGIN}`, form)
      const token = res.data.token;
      cookie.set("pe",token,{path :'/',maxAge:3600});
      const user = res.data.user;
      if ( user.role === '1995') {
        navigate("/dashboard",{replace:true});
        }else if ( user.role === '1996') {
          navigate("/",{replace:true});
        }else if ( user.role === '1997') {
          navigate("/evaluation",{replace:true});
        }else if ( user.role === '1998') {
          navigate("/print",{replace:true});
      }else{
        navigate("/",{replace:true});
      }
      
    } catch (err) {
      console.log(err)
      setError("خطا فى اسم المستخدم او كلمة المرور")
    }finally {
      setLoading(false)
    }
  }
  return (
    <>
     {loading && <Loading />}
      <div style={{ maxWidth: "500px", margin: "100px auto", direction: "rtl", padding: "10px" }}>
        <Card className="shadow">
          <form onSubmit={handleSubmit} style={{ padding: "10px" }}>
            <h3 className="text-center pb-5 pt-3 fw-bold">تسجيل ccالدخول</h3>
            <div className="form-outline mb-4">
              <label className="form-label" htmlFor="form2Example1">الايميل</label>
              <input type="email" id="form2Example1" name="email" className="form-control" 
              value={form.email} onChange={handleFormChange} required />

            </div>


            <div className="form-outline mb-4">
              <label className="form-label" htmlFor="form2Example2">كلمة المرور</label>
              <input type="password" id="form2Example2" name="password" className="form-control" 
              value={form.password} onChange={handleFormChange} required />

            </div>



            <button type="submit" className="btn btn-primary btn-block mb-2 w-100">دخول</button>
          </form>


          {error !== "" && <div className="alert alert-danger mx-2" role="alert">
            {error}
          </div>}
        </Card>
      </div>
    </>
  )
}