import { useEffect, useState } from 'react';
import Loading from '../../../components/Loading'
import axios from 'axios';
import { useOutletContext } from "react-router-dom";
import Cookie from 'cookie-universal'
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import { EVALUATION, FORMCREATE, FORMDELETE, FORMSSHOW, FORMUPDATE, baseUrl } from '../../../Api/Api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
export default function Create() {
    const [loading, setLoading] = useState(false);
    const show = useOutletContext();
    const [evaluations,setEvaluations] = useState(null)
    const [evaluation,setEvaluation] = useState(null)
    const [forms,setForms] = useState(null);
    const [form,setForm] = useState(null)
    const cookie= Cookie();
    const token = cookie.get("pe");
    useEffect(() => {
        try {
            setLoading(true)
            axios.get(`${baseUrl}/${EVALUATION}`,
            {headers : {Authorization : `Bearer ${token}`}}).then(data => {setEvaluations([data.data]);}).catch((err) => console.log(err))
        }catch(err) {

        }finally{
            setLoading(false)
        }
        
        
    },[])
    const handleEvaluationSelect =  (e) => {
        try {
            setLoading(true)
            setEvaluation(e.value)
            getForms(e.value.id)
        }catch(err) {
            console.log(err)
        }finally{
            setLoading(false)
        } 
        
    }
    const getForms = async(id) => {
        await axios.get(`${baseUrl}/${FORMSSHOW}/${id}`,{headers : {Authorization : `Bearer ${token}`}})
        .then(data => {setForms(data.data);})
        .catch((err) => console.log(err))
    }
    const handleAddNewForm = () => {
        let name = prompt("ادخل اسم النموذج","");
        if ( name !== "" && evaluation !== null) {
            axios.post(`${baseUrl}/${FORMCREATE}/${evaluation.id}`,{name:name},{headers : {Authorization : `Bearer ${token}`}})
            .catch((err) => console.log(err))
            getForms(evaluation.id)
        }
    }
    const handleUpdateForm = (id) => {
        let name = prompt("ادخل اسم النموذج","");
        if ( name !== "" && evaluation !== null) {
            axios.post(`${baseUrl}/${FORMUPDATE}/${id}`,{name:name},{headers : {Authorization : `Bearer ${token}`}})
            .catch((err) => console.log(err))
            getForms(evaluation.id)
        }
    }
    const handleDeleteForm = (id) => {
       if ( window.confirm("هل انت متأكد من الحذف")) {
                axios.post(`${baseUrl}/${FORMDELETE}/${id}`,{headers : {Authorization : `Bearer ${token}`}})
                .catch((err) => console.log(err))
                getForms(evaluation.id)
       }
        
    }
    const actionBodyTemplate = (row) => {
        return <>
            <Button type="button" icon="pi pi-pencil" className='rounded-circle' onClick={() => handleUpdateForm(row.id)}></Button>
            <Button type="button" icon="pi pi-times" severity='danger' className='rounded-circle mx-2' onClick={() => handleDeleteForm(row.id)}></Button>
        </>;
    };
    return <> 
        <main id="main" className={show ? "mainNarrow" : "mainWide"} style={{ direction: "rtl" }}>
        {loading && <Loading />}
            <div className="pagetitle">
                <h1>انشاء نموذج التقييم</h1>

                </div>
                <div className='d-flex mt-3'>
                    <Dropdown value={evaluation} onChange={(e) => handleEvaluationSelect(e)} options={evaluations} optionLabel="name" 
                    placeholder="اختر تقييم" className="w-full md:w-14rem" />
                    <Button icon="pi pi-plus-circle" severity="secondary" aria-label="Bookmark" className='rounded-circle mx-2' onClick={handleAddNewForm}/>

                </div>
                <div className='mt-4'>
                <DataTable value={forms} showGridlines  tableStyle={{ minWidth: '50rem' }}>
                    <Column field="name" header="اسم النموذج"  style={{  textAlign: 'right',width: '50%' }}></Column>
                    <Column headerStyle={{ width: '5rem', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={actionBodyTemplate} />
                </DataTable>
                </div>
        </main>
    </>
}